import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import { getMessaging } from 'firebase/messaging'

import { firebaseConfig } from './firebase-config-obj'

const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)
export const analytics = getAnalytics(app)
export const messaging = getMessaging(app)

/*
if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'local') {
    connectAuthEmulator(auth, 'http://localhost:9099/')
}
*/
