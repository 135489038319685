import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import Logo from '../../assets/images/logo.svg'
import MenuIcon from '../../assets/images/menu-icon.svg'

const Navbar = ({ hideLogin, loggedIn }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const [navLinks, setNavLinks] = useState([])

    useEffect(() => {
        if (loggedIn) {
            setNavLinks([
                { name: 'Home', route: '/' },
                { name: 'Pricing', route: '/pricing' },
                { name: 'Account', route: '/user' },
            ])
        } else {
            setNavLinks([
                { name: 'Home', route: '/' },
                { name: 'Pricing', route: '/pricing' },
                { name: 'Login', route: '/login' },
            ])
        }

        if (hideLogin) {
            setNavLinks([
                { name: 'Home', route: '/' },
                { name: 'Pricing', route: '/pricing' },
            ])
        }
    }, [loggedIn, hideLogin])

    return (
        <div>
            <nav
                className="relative w-full flex flex-wrap items-center justify-between py-3 bg-white text-gray-200 border-b xl:border-none navbar navbar-expand-xl navbar-light"
                style={{
                    background: 'white',
                    color: 'rgba(41, 47, 77, 1)',
                    borderColor: 'rgba(223, 238, 255, 1)',
                    borderBottomWidth: '2px',
                }}
            >
                <div
                    className="container-fluid w-full flex flex-wrap items-center justify-end max-w-7xl m-auto"
                    style={{ width: '95%', maxWidth: '1700px' }}
                >
                    <button
                        className="navbar-toggler text-gray-200 border-0 hover:shadow-none hover:no-underline py-2 px-2.5 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none focus:no-underlinew-full flex justify-end"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent1"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <img
                            src={MenuIcon}
                            alt="menu"
                            style={{ height: '20px' }}
                        />
                    </button>
                    <div
                        className="collapse navbar-collapse flex-grow items-center"
                        id="navbarSupportedContent1"
                    >
                        <img
                            src={Logo}
                            alt="logo"
                            style={{ width: '170px' }}
                            onClick={() => navigate('/')}
                            className="desktop-logo cursor-pointer"
                        />
                        <ul
                            className="navbar-nav flex flex-col pl-0 list-style-none ml-auto mobile-nav-list "
                            style={{ marginRight: '20px' }}
                        >
                            {navLinks.map((linkObj, index) => (
                                <li
                                    key={index}
                                    className="nav-item p-2 cursor-pointer"
                                    onClick={() => {
                                        navigate(linkObj.route)
                                    }}
                                >
                                    <a
                                        className={clsx(
                                            'nav-link text-xs xl:text-sm 2xl:text-base hover:text-green-500 transition duration-100 ease-in-out',
                                            location.pathname ===
                                                linkObj.route &&
                                                !(
                                                    location.pathname !== '/' &&
                                                    linkObj.route === '/'
                                                ) &&
                                                'text-green-500'
                                        )}
                                        style={{
                                            fontWeight:
                                                linkObj.name === 'Login' &&
                                                '900',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {linkObj.name}
                                    </a>
                                    <span
                                        className={clsx(
                                            'nav-line  bottom-0 bg-green-500  transition-all transition  ease-in-out duration-200 ease-in-out ',
                                            location.pathname ===
                                                linkObj.route &&
                                                !(
                                                    location.pathname !== '/' &&
                                                    linkObj.route === '/'
                                                ) &&
                                                'active-nav delay-200'
                                        )}
                                    />
                                </li>
                            ))}
                        </ul>
                        <button
                            type="button"
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="light"
                            className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mobile-cta-button justify-center"
                            style={{
                                padding: '14px 28px',
                                width: '252px',
                                height: '52px',
                                background: '#03314bcc',
                                borderRadius: '12px',
                                color: '#F5F8FD',
                                fontSize: '16px',
                                fontWeight: '700',
                                lineHeight: '150%',
                                whiteSpace: 'nowrap',
                            }}
                            onClick={() => {
                                navigate('/join')
                            }}
                        >
                            SET UP ALERT NOW
                        </button>
                    </div>
                    <div
                        className="flex items-center relative mobile-logo"
                        style={{
                            position: 'absolute',
                            left: '20px',
                            top: '10px',
                        }}
                    >
                        <img
                            src={Logo}
                            alt="logo"
                            className="pr-2 "
                            style={{ height: '45px' }}
                        />
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar
